/* Under development Temp message */
.under-develop-message {
    display: inline-block;
    padding: 7px;
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 40px;
    border-radius: 3px;
    background-color: rgb(255, 212, 212);
}





/*
 *
 * Home Page
 *
*/

/* main color: rgb(235, 249, 255) */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'IBM Plex Sans', "Helvetica Neue", "Segoe UI",
        Helvetica, Verdana, Arial, sans-serif;
    color: #435472;
}

html {
    overflow-y: scroll;
    min-height: 100%;
    position: relative;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    font-weight: 600;
}

.hidden {
    display: none;
}

.container {
    max-width: 570px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

nav .container, footer .container {
    max-width: 570px;
    padding-right: 15px;
    padding-left: 15px;
}

.wrap-out {
    position: relative;
}

.wrap-out::before {
    content: '';
    position: absolute;
    right: 5px;
    top: 5px;
    left: -6px;
    bottom: -6px;
    background: rgb(235, 249, 255);
    z-index: 1;
}

.wrap-in {
    border: 2px solid rgb(211, 210, 210);
    position: relative;
    z-index: 2;
}

.thick-border {
    border-right: 10px solid rgb(211, 210, 210);
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

/* fix footer to bottom */
nav {
    /* margin-bottom: calc(100vh - 158px); */
}

#app-container > section {
    margin-bottom: 120px;
    /* min-height: calc(100vh - 158px); */
    /* margin-top: calc(158px - 100vh); */
}


/* loading icon styling */
/* .loading-icon {
    display: inline-block;
    position: fixed;
    z-index: 1;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
}

.loading-icon .wrap-in {
    border: 0;
}

.loading-icon img {
    width: 70px;
} */

@keyframes ld-blink {
    0% {
      opacity: 1;
    }
    49% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
}
@-webkit-keyframes ld-blink {
    0% {
        opacity: 1;
    }
    49% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
.ld.ld-blink {
    -webkit-animation: ld-blink 1s infinite linear;
    animation: ld-blink 1s infinite linear;
}

.network-error-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    padding: 8px;
    z-index: 3;
    text-align: center;
    color: white;
    background-color: #d45c51;
}

.network-error-inline {
    text-align: center;
    margin-top: 40px;
}

.no-match {
    text-align: center;
}


/*
 * Navigation
*/

nav .wrap-in {
    border-width: 0;
    border-bottom: 1px solid rgb(211, 210, 210);
}

nav .container {
    position: relative;
    height: 50px;
}

nav .skip-link {
    position: absolute;
    z-index: 5;
    border: 1px solid rgb(211, 210, 210);
    background: whitesmoke;
    color: #435472;
    padding: 7px;
    top: -37px;
    cursor: pointer;
}

nav .skip-link:focus {
    top: 0;
}

nav .logo {
    display: inline-block;
    float: left;
    vertical-align: middle;
}

/* logo */
nav .logo a {
    display: block;
    padding: 8px;
    height: 34px;
    box-sizing: content-box;
}

nav .logo img {
    display: block;
}

nav .logo a:hover,
nav .logo a.focus-visible:focus {
    outline-width: 0px;
    /* background: white; */
    border-bottom: 3px solid rgb(211, 210, 210);
    transition-duration: 0.2s;
}

/* Make nav logo negative on hover or focus */

/* nav .logo a:hover svg > g > g > g:first-of-type > use,
nav .logo a.focus-visible:focus svg > g > g > g:first-of-type > use {
    fill: white;
}

nav .logo a:hover svg > g > g > g:first-of-type > g > use,
nav .logo a.focus-visible:focus svg > g > g > g:first-of-type > g > use {
    stroke: white;
}

nav .logo a:hover svg > g > g > g:nth-child(2) > use,
nav .logo a:hover svg > g > g > g:nth-child(3) > use,
nav .logo a.focus-visible:focus svg > g > g > g:nth-child(2) > use,
nav .logo a.focus-visible:focus svg > g > g > g:nth-child(3) > use {
    fill: #435472;
}

nav .logo a:hover svg > g > g > g:nth-child(2) > g > use,
nav .logo a:hover svg > g > g > g:nth-child(3) > g > use,
nav .logo a.focus-visible:focus svg > g > g > g:nth-child(2) > g > use,
nav .logo a.focus-visible:focus svg > g > g > g:nth-child(3) > g > use {
    stroke: #435472;
} */

/* hamburger icon */
nav .hamburger {
    display: inline-block;
    padding: 13px 16px 9px 13px;
    float: right;
    cursor: pointer;
}

nav .hamburger:hover,
nav .hamburger.focus-visible:focus {
    outline-width: 0px;
    /* background: white; */
    border-bottom: 3px solid rgb(211, 210, 210);
    transition-duration: 0.1s;
}

/* navigation list */
nav ul {
    position: absolute;
    right: 15px;
    left:  15px;
    top: 50px;
    z-index: 1;
    border-top: 2px solid rgb(211, 210, 210);
    border-bottom: 2px solid rgb(211, 210, 210);
    text-align: center;
}

nav ul li {
    display: block;
    font-size: 14px;
    font-weight: bold;
}

nav ul li a {
    border-bottom: 1px solid rgb(211, 210, 210);
    background: whitesmoke;
    display: block;
    padding: 16px 13px;
    color: #435472;
}

nav ul li a:hover,
nav ul li a.focus-visible:focus {
    outline-width: 0;
    background: #fff;
    transition-duration: 0.2s;
}


/*
 * Header
*/

header {
    text-align: center;
    position: relative;
    z-index: 1;
}

header .container {
    padding-top: 45px;
    padding-bottom: 10px;
}

/* logo */
header .logo {
    margin-right: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

header .logo.wrap-out::before {
    border-radius: 100%;
}

header .logo .wrap-in {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    padding: 10px;
    border-width: 2px;
}

/* header .logo pre{
    top: 8px;
    left: 5px;
    font-size: 1.8px;
    position: absolute;
    font-family: monospace;
    text-align: initial;
    font-weight: 1000;
} */

/* personal photo */
header .personal-photo {
    width: 70px;
    height: 70px;
    vertical-align: top;
    display: inline-block;
    border-radius: 50%;
}

header .personal-photo.wrap-out::before {
    border-radius: 100%;
}

header .personal-photo .wrap-in {
    width: 70px;
    height: 70px;
    padding: 10px;
    border-radius: 50%;
    border-width: 2px;
}

header .personal-photo img,
header .logo img {
    width: 100%;
    border-radius: 100%;
    position: relative;
    z-index: 1;
}

/* headline */
header .headline {
    margin-top: 45px;
}

.headline .wrap-in {
    padding: 20px;
    border-width: 2px;
}

header .headline h1{
    font-size: 2.2em;
    line-height: 140%;
}


/*
 * Main
*/

main .container {
    padding-top: 35px;
    padding-bottom: 35px;
}

main li {
    margin-bottom: 45px;
    line-height: 150%;
}

main li:last-of-type {
    margin-bottom: 10px;
}

main li .wrap-in {
    padding: 20px;
}

main li .wrap-in::before {
    content: '>_';
    float: left;
    width: 40px;
    font-weight: bold;
    color: grey;
}

main li .wrap-in p {
    width: calc(100% - 40px);
    float: right;
    text-align: justify;
}

main li .wrap-in::after {
    content: ' ';
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
}


/*
 * Footer
*/

footer.wrap-out {
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
}

footer .wrap-in {
    border-width: 0;
    border-top: 1px solid rgb(211, 210, 210);
    color: grey;
    text-align: center;
}

footer .wrap-in::after {
    content: '';
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
}

footer p {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
}

footer p.social-links {
    /* height: 40px; */
    padding: 0;
    padding-left: 8px;
    text-align: center;
}

footer p.social-links a {
    margin-right: 8px;
    display: inline-block;
}

footer p.social-links a:last-child {
    /* margin-right: 0; */
}

footer p.social-links a svg {
    vertical-align: top;
    box-sizing: content-box;
    padding: 10px;
}



/*
 *
 * Collection's page
 *
*/

/*
 * Header
*/

header.collection .container {
    padding-top: 35px;
    padding-bottom: 35px;
}

header.collection .headline {
    margin-top: 0;
    text-align: left;
}

header.collection .headline h1 {
    text-decoration: underline;
}


/*
 * Main
*/

main.collection .container {
    padding-top: 0;
    padding-bottom: 0;
}

main.collection ul li .wrap-in::before {
    content: '';
    display: none;
}

/* search bar */

main.collection .search {
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
}

main.collection .search .wrap-in {
    border-width: 1px;
}

main.collection .search input {
    width: calc(100% - 120px);
    display: inline-block;
    border: 0;
    padding: 12px;
    padding-right: 46px;
    letter-spacing: 1px;
    font-size: 1em;
}

main.collection .search button.cancel-btn {
    border: 0;
    padding: 0;
    cursor: pointer;
    position: absolute;
    right: 120px;
    top: 0;
    background: none;
    width: 45px;
    height: 45px;
    color: rgb(67, 84, 114);
}

main.collection .search button.search-btn {
    width:120px;
    border: 0;
    padding: 12px;
    cursor: pointer;
    font-size: 1em;
}

/* search keywords */

main.collection ul.search-keywords {
    /* margin-bottom: 20px; */
    position: relative;
    z-index: 1;
}

main.collection ul.search-keywords li {
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 7px;
}

main.collection ul.search-keywords li p.wrap-in {
    padding: 5px;
    padding-right: 7px;
    padding-left: 7px;
    cursor: pointer;
    border-width: 1px;
}

main.collection ul.search-keywords li p.wrap-in:hover,
main.collection ul.search-keywords li p.wrap-in.focus-visible:focus {
    background: whitesmoke;
    transition-duration: 0.2s;
}

.active-keyword {
    background-color: rgb(224, 230, 235);
}

/* results */

main.collection ul.search-results {
    text-align: center;
}

main.collection ul.search-results li {
    width: 100%;
    max-width: 370px;
    display: inline-block;
    vertical-align: top;
    /* margin-bottom: 30px; */
    margin-top: 20px;
    margin-bottom: 10px;
}

main.collection ul.search-results li .wrap-in {
    height: 0px;
    padding: 0px;
    padding-bottom: calc(55% - 4px);
    display: block;
    font-weight: initial;
}

main.collection ul.search-results li .image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    /* background-size: auto auto; */
    background-size: contain;
    /* background-position: center center; */
    background-position: initial;
    background-repeat: no-repeat;
}

.current .image-container::after {
    content: 'working on';
    left: -15px;
    top: 20px;
    position: absolute;
    z-index: 3;
    background: rgb(224, 230, 235);
    border: 1px solid rgb(211, 210, 210);
    padding: 3px 7px;
    font-size: 14px;
}

main.collection ul.search-results li .text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: rgb(224, 230, 235);
    color: #435472;
    border-top: 2px solid rgb(211, 210, 210);
    padding-right: 10px;
    padding-left: 5px;
    text-align: left;
}

main.collection ul.search-results li .text p {
    width: initial;
    float: initial;
    text-align: initial;
    display: inline-block;
    width: calc(100% - 17px);
    padding: 5px;
    vertical-align: middle;
}

.lang-indicator {
    display: inline-block;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    vertical-align: middle;
}

/* language colors */
.lang-indicator.javascript {
    border: 2px solid white;
    background: #f1e05a;
}

.lang-indicator.html {
    border: 2px solid white;
    background: #e34c26;
}

.lang-indicator.css {
    border: 2px solid white;
    background: #563d7c;
}

.lang-indicator.python {
    border: 2px solid white;
    background: #3572A5;
}

.lang-indicator.markdown {
    border: 2px solid white;
    background: #ccc;
}

.lang-indicator.sql {
    border: 2px solid white;
    background: #ccc;
}

.lang-indicator.shell {
    border: 2px solid white;
    background: #89e051;
}

main.collection ul.search-results li .wrap-in::before,
main.collection ul.search-results li .wrap-in.focus-visible::before {
    content: '< view >';
    position: absolute;
    top: calc(50% - 9px - 16px);
    left: calc(50% - 37.8px);
    z-index: 3;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    width: initial;
    font-weight: initial;
    display: initial;
    opacity: 0;
    color: white;
    background: #435472;
}

/* hover & focus effects */
main.collection ul.search-results li .wrap-in:hover,
main.collection ul.search-results li .wrap-in.focus-visible:focus {
    border-color: #435472;
    outline: 0;
    transition-duration: 0.4s;
}

main.collection ul.search-results li .wrap-in:hover .image-container::before,
main.collection ul.search-results li .wrap-in.focus-visible:focus 
    .image-container::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(216, 208, 208, 0.541);
    transition-duration: 0.4s;
}

main.collection ul.search-results li .wrap-in:hover::before,
main.collection ul.search-results li .wrap-in.focus-visible:focus::before {
    opacity: 1;
    transition-duration: 0.4s;
}

main.collection ul.search-results li .wrap-in:hover .image-container::after,
main.collection ul.search-results li .wrap-in.focus-visible:focus
    .image-container::after {
    border-color: #435472;
    transition-duration: 0.4s;
}

main.collection ul.search-results li .wrap-in:hover .text,
main.collection ul.search-results li .wrap-in.focus-visible:focus .text {
    border-color: #435472;
    background-color: #435472;
    transition-duration: 0.4s;
}

main.collection ul.search-results li .wrap-in:hover .text p,
main.collection ul.search-results li .wrap-in.focus-visible:focus .text p {
    color: white;
    transition-duration: 0.4s;
}



/*
 *
 * skills page
 *
*/

/* results */

main.skills ul.search-results {
    text-align: left;
}

main.skills ul.search-results li {
    width: 45%;
    max-width: 170px;
}

main.skills ul.search-results li:nth-child(odd) {
    margin-right: 10%;
}

main.skills ul.search-results li .wrap-in {
    height: initial;
    padding: initial;
    padding-bottom: calc(100% - 4px);
}

main.skills ul.search-results li .image-container {
    /* border: 1px dashed red; */
    bottom: 58px;
    background-size: auto auto;
    background-position: center center;
}


main.skills ul.search-results li .text {
    /* position: initial; */
    border-top: 0;
    min-height: 58px;
}

/* hover & focus effects */ 
main.skills ul.search-results li .wrap-in:hover::before,
main.skills ul.search-results li .wrap-in.focus-visible:focus::before {
    display: none;
}



/*
 *
 * Document's page
 *
*/

header.doc .container {
    padding-top: 35px;
    padding-bottom: 35px;
}

.doc .container,
nav.doc .container, footer.doc .container {
    /* max-width:  500px; */
}

.doc .go-to-parent {
    text-align: left;
    margin-bottom: 20px;
}

.doc .go-to-parent svg {
    display: inline-block;
    vertical-align: middle;
}

.doc .go-to-parent p { 
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

main.doc .jumbotron,
main.doc .points {
    width: 100%;
}


/*
 * Header
*/

header.doc .container {
    padding-top: 35px;
}

header.doc .headline {
    margin-top: 0;
    text-align: left;
}

header.doc .headline .wrap-in {
    padding-right: 20px;
}

header.doc .headline h1 {
    text-decoration: underline;
    display: inline-block;
    width: calc(100% - 30px);
    vertical-align: middle;
    margin-right: 10px;
}

.doc .lang-indicator {
    width: 20px;
    height: 20px;
}


/*
 * Main
*/

main.doc .container {
    padding-top: 0;
    padding-bottom: 0;
}

main.doc ul li .wrap-in::before {
    content: '';
    display: none;
}

main.doc section {
    margin-bottom: 25px;
}


/*
 * jumbotron section
*/

.doc .jumbotron {
    position: relative;
    z-index: 1;
}

.doc .jumbotron .screenshot div {
    display: block;
    border-bottom: 1px solid rgb(211, 210, 210);
    height: 250px;
    text-indent: -9999px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto auto;
}

.doc.skills .jumbotron .screenshot div {
    background-size: auto auto;
    background-position: center center;
}

.jumbotron .summary {
    padding: 15px;
    text-align: justify;
}

.doc .jumbotron .wrap-in {
    border-color: rgb(211, 210, 210);
}


/* 
 * Links Table
*/

.doc .links {
    width: 100%;
    margin-top: -25px;
}

.doc .links ul {
}

.doc .links ul li {
    margin-bottom: 0;
}

.doc .links ul li a.wrap-in {
    display: block;
    text-align: center;
    padding: 10px;
    border-width: 2px;
    border-top-width: 0;
    /* border: 1px dashed red; */
}

.doc .links ul li a.wrap-in:hover,
.doc .links ul li a.wrap-in.focus-visible:focus {
    background: whitesmoke;
    transition-duration: 0.2s;
}


/*
 * Routine table section
*/

main.doc section.routine {
    margin-top: 53px;
    margin-bottom: -18px;
}

.routine ul {
    position: relative;
    padding-top: 11px;
}

.routine ul::before {
    content: '';
    position: absolute;
    right: 5px;
    top: 5px;
    left: -6px;
    bottom: 12px;
    /* background: rgb(235, 249, 255); */
    z-index: 1;
}

.routine li.wrap-out {
    display: inline-block;
    vertical-align: top;
    margin-right: 22px;
    margin-bottom: 19px;
}

.routine li.wrap-out::before {
    /* background: none; */
}

.routine .wrap-in {
    padding: 13px;
    border-width: 1px;
    border-color: rgb(211, 210, 210);
}

.routine li .wrap-in p {
    padding: initial;
    width: auto;
    float: none;
    text-align: initial;
}

.routine li .wrap-in > p {
    font-style: italic;
    font-weight: bold;
    text-decoration: underline;
}

.routine li .answer {
    display: inline-block;
    margin-top: 8px;
}

.routine .wrap-in .answer > p {
    display: inline-block;
}

.routine .wrap-in .answer > p:first-child {
    margin-right: 15px;
}


/*
 * Technical table section
*/

/* Single remote point (projects, snippets ...etc) */

.pointSection {
    margin-top: 35px;
}

.pointSection:first-child {
    margin-top: 53px;
}

.pointSection > h2 {
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid rgb(211, 210, 210);
}

.pointSection > ul {
    margin-left: 20px;
}

.pointSection li.wrap-out {
    display: inline-block;
    margin-right: 18px;
    margin-bottom: 18px;
}

.pointSection li a.wrap-in {
    border-width: 1px;
    padding: 8px;
    display: block;
}

.pointSection li a.wrap-in:hover,
.pointSection li a.wrap-in:focus {
    background: whitesmoke;
    transition-duration: 0.2s;
}




/*
 * details section
*/

.doc .details {
    margin-top: 40px;
}

.doc .details .wrap-in {
    padding: 20px;
    border-color: rgb(211, 210, 210);
    border-width: 1px;
}

.doc .details .writing-section {
    margin-bottom: 50px;
}

.doc .details .writing-section:last-child {
    margin-bottom: 0;
}

.doc .details h3 {
    margin-bottom: 20px;
    font-size: 1.3em;
}

.doc .details p {
    margin-bottom: 13px;
    text-align: justify;
}

.doc .details img {
    width: 100%;
    margin-bottom: 10px;
}




/*
 *
 * 404 page
 *
*/

/*
 * Header
*/

header.not-found .container {
    padding-top: 35px;
}

header.not-found .headline {
    margin-top: 0;
}


/*
 * Responsive
*/

@media screen and (min-width: 255px) {
    /* header */
    header .logo {
        margin-right: 2%;
        width: 100px;
        height: 100px;
    }
    
    header .logo .wrap-in {
        width: 100px;
        height: 100px;
    }
    
    header .logo pre{
        top: 12px;
        left: 7px;
        font-size: 2.6px;
    }
    
    header .personal-photo {
        width: 100px;
        height: 100px;
        vertical-align: top;
    }
    
    header .personal-photo .wrap-in {
        width: 100px;
        height: 100px;
    }

    /* footer */
    footer p.social-links {
        padding-left: 15px;
    }

    footer p.social-links a {
        margin-right: 15px;
    }
}


@media screen and (min-width: 305px) {
    /* header */
    header .logo {
        margin-right: 5%;
        width: 120px;
        height: 120px;
    }
    
    header .logo .wrap-in {
        width: 120px;
        height: 120px;
    }
    
    /* header .logo pre{
        top: 10px;
        left: 10px;
        font-size: 3px;
    } */
    
    header .personal-photo {
        width: 120px;
        height: 120px;
        vertical-align: top;
    }
    
    header .personal-photo .wrap-in {
        width: 120px;
        height: 120px;
    }
}

@media screen and (min-width: 351px) {
    /* fix footer to bottom */
    nav {
        /* margin-bottom: calc(100vh - 138px); */
    }

    #app-container > section {
        margin-bottom: 100px;
        /* min-height: calc(100vh - 138px); */
        /* margin-top: calc(138px - 100vh); */
    }

    /* 
     * Links Table
    */

    .doc .links {
        display: table;
        table-layout: fixed;
    }

    .doc .links ul {
        display: table-row;
    }

    .doc .links ul li {
        display: table-cell;
        margin-bottom: 15px;
    }

    .doc .links ul li a.wrap-in {
        display: block;
        text-align: center;
        padding: 7px;
        /* border-width: 2px; */
        /* border-right: 1px; */
        border-left-width: 0;
        border-top-width: 0;
    }

    .doc .links ul li:first-child a.wrap-in {
        border-left-width: 2px;
    }

    .doc .links ul li a.wrap-in:hover,
    .doc .links ul li a.wrap-in.focus-visible:focus {
        background: whitesmoke;
        transition-duration: 0.2s;
    }

    /* footer */
    footer p.social-links {
        height: 40px;
        padding: 0;
    }
    
    footer p.social-links a {
        margin-right: 13px;
    }
    
    footer p.social-links a:last-child {
        margin-right: 0;
    }
}

@media screen and (min-width: 410px) {
    /* header */
    header .logo {
        margin-right: 10%;
        width: 160px;
        height: 160px;
    }
    
    header .logo .wrap-in {
        width: 160px;
        height: 160px;
    }
    
    /* header .logo pre{
        top: 17px;
        left: 14.5px;
        font-size: 4px;
    } */
    
    header .personal-photo {
        width: 160px;
        height: 160px;
        vertical-align: top;
    }
    
    header .personal-photo .wrap-in {
        width: 160px;
        height: 160px;
    }

    header .headline .wrap-in {
        padding: 30px;
    }

    /* collection and document pages */
    header.collection .headline .wrap-in,
    header.doc .headline .wrap-in {
        padding: 20px;
    }
}


@media screen and (min-width: 500px) {
    main.collection ul.search-results {
        text-align: left;
    }

    main.collection ul.search-results li {
        width: calc(50% - 15px);
        display: inline-block;
        vertical-align: top;
        /* margin-bottom: 30px; */
        margin-top: 20px;
        margin-bottom: 10px;
    }

    main.collection ul.search-results li:nth-of-type(odd) {
        margin-right: 30px;
    }
    
    main.collection ul.search-results li .wrap-in {
        height: 0px;
        padding: 0px;
        padding-bottom: calc(70% - 4px);
    }

    /* skills page */
    main.skills ul.search-results li {
        width: 30%;
        margin-right: 5% !important;
    }

    main.skills ul.search-results li:nth-child(3n) {
        margin-right: 0 !important;
    }
    
    main.skills ul.search-results li .wrap-in {
        height: initial;
        padding-bottom: calc(100% - 4px);
    }

    /* Doc page */
    /* Details section */

    .doc .details .wrap-in {
        padding: 28px 25px;
    }
    
    .doc .details .writing-section {
        margin-bottom: 55px;
    }
    
    .doc .details h3 {
        margin-bottom: 20px;
    }
    
    .doc .details p {
        margin-left: 22px;
        margin-right: 22px;
    }
    
    .doc .details img {
        width: 85%;
    }

}


@media screen and (min-width: 700px){
    /* fix footer to bottom */
    nav {
        /* margin-bottom: calc(100vh - 98px); */
    }

    #app-container > section {
        margin-bottom: 60px;
        /* min-height: calc(100vh - 98px); */
        /* margin-top: calc(98px - 100vh); */
    }

    /* nav */
    nav .container, footer .container
    /* nav.doc .container, footer.doc .container */ {
        max-width: 1290px !important;
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    nav .container {
        position: initial;
    }

    nav .hamburger {
        display: none;
    }

    nav ul li a:hover,
    nav ul li a.focus-visible:focus {
        background: initial;
    }

    .hidden {
        display: initial;
    }

    nav ul {
        float: right;
        width: initial;
        max-width: initial;
        position: initial;
        right: initial;
        top: initial;
        z-index: initial;
        border-top: initial;
        border-bottom: initial;
        text-align: initial;
    }

    nav ul li {
        display: inline-block;
    }

    nav ul li a {
        border-bottom: initial;
        background: initial;
    }

    nav ul li a:hover,
    nav ul li a.focus-visible:focus {
        outline-width: 0px;
        border-bottom: 3px solid rgb(211, 210, 210);
        transition-duration: 0.2s;
    }

    /* footer */
    footer p:first-of-type {
        text-align: left;
        width: 60%;
    }

    footer p:last-of-type {
        text-align: right;
        width: 40%;
    }

    footer p.social-links a {
        margin-right: 3px;
    }
}


@media screen and (min-width: 725px){
    /* nav */
    nav ul li a {
        padding-right: 15px;
        padding-left: 15px;
    }

    /* main */    
    .doc .container {
        max-width: 700px;
    }

    .doc .jumbotron {
        /* max-width:  350px; */
        /* float: right; */
    }

    /* footer */
    footer p.social-links a {
        margin-right: 0;
    }
}

